import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import size from 'lodash/size';
import map from 'lodash/map';
import Option from 'COMPONENTS/header/search_option';
import {
    SEARCH_TYPES,
    searchPattern,
    GTM_EVENTS,
    TIME_ONE_SECOND
} from 'CONST';
import qs from 'qs';
import { AmountLoader } from 'COMMON/loader';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { searchDataReselect } from 'MODULES/reselect/header';
import { Link } from 'react-router-dom';
import { checkNested } from 'MODULES/checkNested';
import { useDebounce } from 'MODULES/useDebounce';
import { gtm } from 'MODULES/gtm';
import { PP_URLS } from 'CONFIG/PP_URLS';
import { getRecentSearches } from 'ACTIONS/search/getRecentSearches';
import SearchInput from 'COMPONENTS/header/search_input/search_input';

const HeaderMiddle = ({ ...props }) => {
    const {
        i18n, showAutocomplete
    } = props;
    const searchActivated = useSelector(state => state.ui.activateSearchInput);
    const dispatch = useDispatch();
    const location = useLocation();

    const searchData = useSelector(state => searchDataReselect(state, { location }));
    const { searchResults, searchQuery, category, lang } = searchData;
    const parsedString = searchQuery && searchQuery.match(searchPattern) && searchQuery.match(searchPattern).join('').trim();

    const [initialInputValue, setInitialInputValue] = useState(props.searchRouteParam || '');
    const [lastVisitedCategory, setLastVisitedCategory] = useState(false);
    const [searchInitialValue, setSearchInitialValue] = useState(props.searchRouteParam);
    const { debounce: debounceSearch } = useDebounce();
    const { debounce: debounceSuggestion } = useDebounce();

    useEffect(() => window.addEventListener('click', handleClick), []);
    useEffect(() => {
        dispatch(getRecentSearches());
    }, []);

    useEffect(() => {
        if (initialInputValue && searchInitialValue) {
            handleSearch(initialInputValue);
            setSearchInitialValue(false);
        }
        if (checkNested(category, 'parents') && !lastVisitedCategory) {
            setLastVisitedCategory(category);
        }
    }, [searchResults, initialInputValue, searchInitialValue, lastVisitedCategory]);

    const handleSearchDebounce = debounceSearch((term, allowCallback) => {
        props.searchByQuery(term, 'combined', false, false, undefined, undefined, allowCallback);
    }, 300);

    const handleSearchSuggestDebounce = debounceSuggestion((term) => {
        props.searchByQuery(term, 'suggestion');
    }, TIME_ONE_SECOND);

    const handleSeeAll = (gtmKey, isEnter = false) => {
        props.toggleShowAutocomplete(false);
        props.history.push({
            pathname: PP_URLS.SEARCH_QUERY(searchQuery, props.lang),
            isEnter
        });
        gtm(gtmKey);
    };

    const handleSeeAllCategory = () => {
        props.toggleShowAutocomplete(false);
        props.setSearchSuggestion(searchQuery);
        props.history.push({
            pathname: PP_URLS.SEARCH_QUERY(searchQuery, props.lang),
            search: `categories[]=${category.id}`
        });
        gtm(GTM_EVENTS.headerSearchSeeAllCategory);
    };

    useEffect(() => {
        if (props.showAutocomplete) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [props.showAutocomplete]);

    const handleClick = (e) => {
        const { target } = e;
        if (target.id !== 'header-search') {
            props.toggleShowAutocomplete(false);
        }
        if (target.id === 'header-search' && searchResults.length) {
            props.toggleShowAutocomplete(true);
        }
        if (target.className && typeof target.className.includes !== 'undefined' && target.className.includes('pp-search') && isMobile) {
            props.toggleShowAutocomplete(true);
            const headerSearch = document.getElementById('header-search');
            if (headerSearch) {
                headerSearch.focus();
            }
        }
    };

    const handleOnChange = (option) => {
        let to = {};
        let skipSuggestPost = false;
        switch (option.type) {
            case 'category':
                to = {
                    pathname: PP_URLS.CATEGORY(option.slug)
                };
                skipSuggestPost = true;
                gtm(GTM_EVENTS.headerSearchClickCategory);
                break;
            case 'region':
                if (option.regionId) {
                    to = {
                        pathname: PP_URLS.CATEGORY(option.slug),
                        search: qs.stringify({ region: option.regionId })
                    };
                    gtm(GTM_EVENTS.headerSearchClickCategory);
                }

                break;
            case 'see-all':
                to = {
                    pathname: PP_URLS.SEARCH_QUERY(props.searchQuery)
                };
                break;
            case SEARCH_TYPES.suggestion:
                to = {
                    pathname: PP_URLS.SEARCH_QUERY(option.name)
                };
                break;
            default:

                break;
        }
        if (option && option.adTexts) {
            to = {
                pathname: PP_URLS.PP(option.id, option.category.slug[lang])
            };
            gtm(GTM_EVENTS.headerSearchClickAd);
        }
        setInitialInputValue('');
        setSearchInitialValue(true);
        let suggestion = checkNested(option, ['categoryTitle'], checkNested(option, 'name', ''));
        if (checkNested(option, 'data') && checkNested(option, 'data.name') !== '') {
            suggestion = option.data.name;
        }
        if (!skipSuggestPost) props.setSearchSuggestion(suggestion);
        props.history.push(to);
        props.clearAutocomplete(props.searchQuery);
        props.setSearchTerm(props.searchQuery, SEARCH_TYPES.combined);
        props.toggleShowAutocomplete(false);
    };

    const handleSearch = (term, allowCallback) => {
        props.setSearchTerm(term, SEARCH_TYPES.combined);
        setInitialInputValue(term);
        if (term) {
            handleSearchDebounce(term, allowCallback);
            handleSearchSuggestDebounce(term);
        }
    };

    return (
        <div className="header-middle-input">
            <Link
                to={{
                    pathname: PP_URLS.CREATE,
                    state: { step: 1, lastVisitedCategory }
                }}
                className="btn btn-primary btn-create pe-0 pe-md-2"
                data-test="toolbar-classified-create-button"
                aria-label={i18n.__('Create')}
            >
                <i className="pp-landing pp-plus-circle" />
                <span className="d-none d-md-inline-flex">{i18n.__('Create')}</span>
            </Link>
            <div className="header-search">
                <div
                    className={classNames(
                        searchResults.length > 0 ? 'search-main input-clear highlight d-flex' : 'input-group search-main input-clear',
                        {
                            highlight: !!searchActivated
                        }
                    )}
                    data-test="toolbar-classified-search-container"
                    key="search-container"
                >
                    {!isMobile || showAutocomplete ? (
                        <SearchInput
                            i18n={i18n}
                            initialInputValue={initialInputValue}
                            handleSearch={handleSearch}
                            handleSeeAll={handleSeeAll}
                            handleClick={handleClick}
                            toggleShowAutocomplete={props.toggleShowAutocomplete}
                            debounceSearch={debounceSearch}
                            parsedString={parsedString}
                            setSearchSuggestion={props.setSearchSuggestion}
                        />
                    ) : null}
                    {props.showAutocomplete && size(searchResults) > 0 && (
                        <>
                            <div
                                className="Select-menu-outer"
                                id="Select-menu-outer"
                                key="Select-menu-outer"
                                style={{ zIndex: '12345' }}
                            >
                                {searchResults.length > 0 && map(searchResults, (option) => (
                                    <div key={`${option.name || option.id}_${option.type}`}>
                                        <Option
                                            option={option}
                                            onFocus={() => {
                                                if (!props.showAutocomplete) {
                                                    props.toggleShowAutocomplete(true);
                                                }
                                            }}
                                            onSelect={handleOnChange}
                                        />
                                    </div>
                                ))}
                                {category && category.id && props.showAutocomplete && searchResults.length > 0 && (
                                    <button
                                        id="see-all-category"
                                        key="see-all-category"
                                        className="btn btn-primary w-100 me-0"
                                        style={{ zIndex: '12345' }}
                                        onMouseDown={handleSeeAllCategory}
                                    >
                                        <i className="pp-landing pp-search" aria-hidden="true" />
                                        {i18n.__('Search in this category')}
                                    </button>
                                )}
                            </div>
                            <button
                                id="see-all"
                                key="see-all"
                                className="btn btn-primary w-100 see-all me-0"
                                onMouseDown={() => handleSeeAll(GTM_EVENTS.headerSearchSeeAll)}
                                aria-label={i18n.__('All results')}
                            >
                                <i className="pp-landing pp-search" aria-hidden="true" />
                                {i18n.__('All results')}
                                <span className="ps-1">
                                    (
                                    {searchData.totalCount}
                                    )
                                </span>
                            </button>
                        </>
                    )}
                    <div className="search-btn-wrapper" data-test="toolbar-classified-search-button-container">
                        {props.searchIsFetching && <div className="search-btn-loader" style={{ zIndex: '99999' }}>{AmountLoader}</div>}
                        {showAutocomplete && initialInputValue !== '' && (
                            <button
                                onMouseDown={() => {
                                    props.setSearchTerm('', SEARCH_TYPES.combined);
                                    setInitialInputValue('');
                                }}
                                style={{ zIndex: '12345' }}
                                tabIndex={0}
                                className="btn-only-icon"
                                aria-label={i18n.__('Close')}
                            >
                                <i
                                    key="clear"
                                    className="pp-landing pp-times p-2 cursor-pointer"
                                    id="clear"
                                    data-test="toolbar-classified-search-clear-button"
                                />
                            </button>
                        )}
                        {(parsedString || isMobile) && [
                            <button
                                key="search"
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    if (parsedString && showAutocomplete) {
                                        props.setSearchSuggestion(parsedString);
                                        props.history.push(PP_URLS.SEARCH_QUERY(parsedString), { searchQuery: parsedString });
                                    }
                                    gtm(GTM_EVENTS.headerSearchButton);
                                }}
                                type="button"
                                className="btn btn-primary btn-search"
                                onClick={(e) => handleClick(e)}
                                data-test="toolbar-classified-search-button"
                                tabIndex={0}
                                aria-label={i18n.__('Search')}
                            >
                                <i className="pp-landing pp-search" />
                                <span className="d-none d-lg-inline-block">{i18n.__('Search')}</span>
                            </button>
                        ]}
                    </div>
                </div>
            </div>
            {showAutocomplete && <div className="modal-backdrop dark-backdrop" />}
        </div>
    );
};

export default HeaderMiddle;
