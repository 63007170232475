import get from 'lodash/get';
import { createSelector } from 'reselect';
import qs from 'qs';
import { checkNested } from 'MODULES/checkNested';
import { prepareHash } from 'MODULES/prepareHash';

const emptyObject = {};

export const formValueSelect = (form, field) => createSelector(
    state => checkNested(state.form, form, emptyObject),
    formState => get(formState, `values.${field}`) || get(formState, `initial.${field}`)
);


export const locationHashState = createSelector(
    (state, props) => props.location,
    location => checkNested(location, 'hash')
);

export const locationHashStateParsed = createSelector(
    (state, props) => props.location,
    location => prepareHash(checkNested(location, 'hash'))
);

export const modalOpenedFromLinkSelector = createSelector(
    (state, props) => props.location,
    location => qs.parse(location.search, { ignoreQueryPrefix: true }).modalOpened
);

export const locationPathnameState = createSelector(
    (state, props) => props.location,
    location => checkNested(location, 'pathname', window.location.pathname)
);

export const locationPathnamePpTypeState = createSelector(
    (state, props) => props.location,
    (state, props) => props.ppType,
    (location, ppType) => `${checkNested(location, 'pathname', window.location.pathname)}_${ppType}`
);
