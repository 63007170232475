import {
    CHANGE_HOME_DISABLED_CATEGORIES,
    GET_HOME_CATEGORIES, GET_HOME_CATEGORIES_ACTIVE_CHILD, GET_HOME_CATEGORIES_AD_COUNT,
    GET_HOME_CATEGORIES_LOTS,
    GET_HOME_COUNT, GET_HOME_DISABLED_CATEGORIES,
    GET_HOME_FILTERS,
    GET_HOME_SUBCATEGORIES, SET_FILTER_CATEGORY
} from 'ACTIONS';
import find from 'lodash/find';
import set from 'lodash/set';
import slice from 'lodash/slice';
import map from 'lodash/map';
import filter from 'lodash/filter';
import omit from 'lodash/omit';
import { TRANSLATION_FOR_OTHER } from 'CONFIG';
import { HOME_ACTIVE_CHILD_COUNT } from 'CONST';
import { checkNested } from 'MODULES/checkNested';

const INITIAL_STATE = () => ({
    subcategories: {},
    filterCategory: {},
    filters: {},
    lotCount: null,
    homeCategoriesLots: {},
    homeDisabledCategories: null
});

export default function (prevState, action, cookies) {
    const state = prevState || INITIAL_STATE(checkNested(cookies, 'language'));
    switch (action.type) {
        case GET_HOME_SUBCATEGORIES:
            return { ...state, subcategories: { ...state.subcategories, [action.category]: action.payload } };
        case SET_FILTER_CATEGORY:
            return { ...state, filterCategory: { ...state.filterCategory, [action.level]: action.category } };
        case GET_HOME_FILTERS:
            return { ...state, filters: { ...state.filters, [action.id]: action.payload }, selectedCategory: action.id };
        case GET_HOME_COUNT:
            return { ...state, lotCount: { ...state.lotCount, [action.id]: action.payload } };
        case GET_HOME_CATEGORIES: {
            return {
                ...state,
                homeCategoriesLots: {
                    ...state.homeCategoriesLots,
                    [action.lang]: action.category
                }
            };
        }
        case GET_HOME_CATEGORIES_LOTS:
            const category = find(state.homeCategoriesLots[action.lang], { id: action.categoryId });
            set(category, 'lots', action.lots);

            return { ...state,
                homeCategoriesLots: {
                    ...state.homeCategoriesLots,
                    [action.lang]: [...checkNested(state.homeCategoriesLots, [action.lang], [])]
                } };
        case GET_HOME_CATEGORIES_ACTIVE_CHILD:
            map(action.payload, (category) => {
                if (category) {
                    const cat = find(state.homeCategoriesLots[action.lang], { id: category.id });
                    const catExcludedOther = filter(category.activeChildren, children => (children.name !== TRANSLATION_FOR_OTHER[action.lang]));
                    set(cat, 'activeChildren', slice(catExcludedOther, 0, HOME_ACTIVE_CHILD_COUNT));
                }
            });

            return { ...state,
                homeCategoriesLots: {
                    ...state.homeCategoriesLots,
                    [action.lang]: [...state.homeCategoriesLots[action.lang]]
                } };
        case GET_HOME_CATEGORIES_AD_COUNT:
            map(action.payload.children, (category) => {
                if (category) {
                    const cat = find(state.homeCategoriesLots[action.lang], { id: category.id });
                    set(cat, 'adCount', category.adCount);
                }
            });

            return state;
        case GET_HOME_DISABLED_CATEGORIES:
            return {
                ...state,
                homeDisabledCategories: action.payload
            };
        case CHANGE_HOME_DISABLED_CATEGORIES:
            return {
                ...state,
                homeDisabledCategories: action.value ? omit(state.homeDisabledCategories, action.category) : { ...state.homeDisabledCategories, [action.category]: { category: { id: action.category } } }
            };
        default:
            return state;
    }
}
