import { SEARCH_TYPES } from 'CONST';
import {
    SEARCH_QUERY,
    CLEAR_SEARCH,
    SET_SEARCH_HISTORY,
    SET_SEARCH_TERM,
    SHOW_AUTOCOMPLETE_TOGGLER, GET_RECENT_SEARCHES
} from 'ACTIONS';

import { setIn } from 'MODULES/setIn';
import { checkNested } from 'MODULES/checkNested';


const INITIAL_STATE = {
    searchResults: { categories: [], combined: [], autocomplete: [], suggestion: [] },
    totalCount: null,
    searchQuery: { categories: '', combined: '', autocomplete: '', suggestion: '' },
    categoriesTotal: 0,
    showAutocomplete: false,
    searchSuggestions: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_SEARCH_HISTORY:
            return action.payload;
        case SEARCH_QUERY:
            let totalCount = action.payload.count === 0 || action.payload.count ? action.payload.count : state.totalCount;
            if (action.suggestion) {
                totalCount = state.totalCount;
            }
            return {
                ...state,
                searchQuery: action.searchType === SEARCH_TYPES.combined ? state.searchQuery : { ...setIn(action.searchType, action.searchQuery, state.searchQuery) },
                searchResults: {
                    ...state.searchResults,
                    [action.searchType]: {
                        ...state.searchResults[action.searchType],
                        [action.searchQuery]: action.searchType === SEARCH_TYPES.categories && checkNested(state.searchResults, [action.searchType, action.searchQuery])
                            ? { ...state.searchResults[action.searchType][action.searchQuery], ...action.payload.data }
                            : action.payload.data
                    }
                },

                totalCount,
                categoriesTotal: action.searchType === SEARCH_TYPES.categories ? action.payload.count : state.categoriesTotal
            };
        case SHOW_AUTOCOMPLETE_TOGGLER:
            return {
                ...state,
                showAutocomplete: action.payload
            };
        case GET_RECENT_SEARCHES:
            return {
                ...state,
                searchSuggestions: action.payload
            };
        case SET_SEARCH_TERM:
            return {
                ...state,
                searchQuery: { ...setIn(action.searchType, action.payload, state.searchQuery) }
            };
        case CLEAR_SEARCH:
            return action.payload || action.payload === '' ? { ...setIn(`searchResults.combined.${action.payload}`, INITIAL_STATE.searchResults.combined[action.payload], state) } : INITIAL_STATE;
        default:
            return state;
    }
}
