export const PP_TYPE = {
    list: 'list',
    map: 'map',
    infinite: 'infinite',
    popular: 'popular',
    mapList: 'mapList',

    mapListPp: 'mapListPp',
    recently_added: 'recently_added',
    similar: 'similar',
    expired: 'expired',
    my: 'myItems',
    search: 'search',
    myCompany: 'myCompanyLots',
    myCompanyLotsXML: 'myCompanyLotsXML',
    publicCompany: 'publicCompanyLots',
    bookmarks: 'bookmarks',
    user: 'user',
    bookmarksMy: 'bookmarksMy',
    filters: 'filters',
    filtersCategory: 'filtersCategory',
    digest: 'digest',
    smallPpsList: 'smallPpsList',
    ppCompare: 'ppCompare'
};
