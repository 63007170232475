import { init } from '@sentry/react';

import productionConfig from 'CONFIG/env/production';

const process = { env:  productionConfig };
import { checkNested } from 'MODULES/checkNested';



const sentry = checkNested(process.env, 'SENTRY_KEY');

if (window.location.hostname.indexOf('pp.lv') !== -1 && !(/HeadlessChrome/.test(window.navigator.userAgent))) {
    init({
        dsn: sentry,
        release: __GIT_REV__,
        replaysOnErrorSampleRate: 1.0,
        integrations: [],
        ignoreErrors: [
            /^Network Error$/,
            /^Request aborted$/,
            /^Network request failed$/,
            /^Failed to fetch$/,
            /^GSI_LOGGER$/,
            /GSI_LOGGER/,
            /unknown/,
            /^<unknown>$/, // GSI_LOGGER throw unknown error in console
            /^Request failed with status code 404$/,
            'Error: Network Error',
            'Network Error',
            'Request aborted',
            'Network request failed',
            'Failed to fetch',
            'NetworkError',
            /^Unexpected token.*/
        ],
        beforeSend(event, hint) {
            if (hint && hint.originalException instanceof Event && hint.originalException.includes('GSI_LOGGER')) {
                return null;
            }
            return event;
        }
    });
}
