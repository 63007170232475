import React from 'react';
import size from 'lodash/size';
import { Link } from 'react-router-dom';
import { UI } from 'CONST';
import { AmountLoader } from 'COMMON/loader';
import Badge from 'COMMON/badge';
import { useSelector } from 'react-redux';
import { rightReselect } from 'MODULES/reselect/header';
import { buildImgUrl } from 'MODULES/buildImgUrl';
import { checkNested } from 'MODULES/checkNested';
import { PP_URLS } from 'CONFIG/PP_URLS';
import GoToCompare from 'COMMON/goToCompare';


const HeaderRight = ({ setIsToggled, i18n }) => {
    const headerRight = useSelector(rightReselect);
    const { mSelect, isCreate, avatarIsFetching, showMessaging, isLoggedIn, userData, bookmarkCount, unseenFilters, unreadMessages, userLots, companyLotsAmount } = headerRight;
    if (isLoggedIn && userData && !isCreate && !size(mSelect)) {
        return (
            <div id="header-badges" className="header-right">
                <div className="d-flex justify-content-end h-100" data-test="toolbar-button-container">
                    <GoToCompare />
                    <Link style={{ width: '39px' }} title={i18n.__('My Classifieds')} to={PP_URLS.ITEMS} className="d-none d-lg-block" id="header-my-classifieds" aria-label={i18n.__('My Classifieds')}>
                        <span className="button-myads" data-test="toolbar-my-items">
                            <Badge
                                badgeMyAds
                                value={userLots}
                            />
                            <i className="pp-landing pp-my-lots" />
                        </span>
                    </Link>

                    {checkNested(userData, ['company']) && (
                        <Link style={{ width: '39px' }} title={i18n.__('My company classifieds')} to={PP_URLS.COMPANY} className="d-none d-lg-block" id="header-company-classifieds" aria-label={i18n.__('My company classifieds')}>
                            <span className="button-my-company" data-test="toolbar-company-items">
                                <Badge
                                    badgeMyCompany
                                    value={companyLotsAmount}
                                />
                                <i className="pp-landing pp-company-lots" />
                            </span>
                        </Link>
                    )}

                    <Link style={{ width: '39px' }} title={i18n.__('Favorites')} to={{ pathname: PP_URLS.BOOKMARKS, state: { category: null } }} className="d-none d-lg-block" id="header-bookmarks" aria-label={i18n.__('Favorites')}>
                        <span className="button-bookmarks" data-test="toolbar-bookmarks">
                            <Badge
                                badgeBookmarks
                                value={bookmarkCount}
                            />
                            <i className="pp-landing pp-heart" />
                        </span>
                    </Link>

                    {showMessaging
                        && (
                            <Link style={{ width: '39px' }} title={i18n.__('Messages')} to={{ pathname: PP_URLS.MESSAGES, state: null }} className="d-none d-lg-block" id="header-messages" aria-label={i18n.__('Messages')}>
                                <span className="button-chat" data-test="toolbar-messages">
                                    <Badge
                                        badgeChat
                                        value={unreadMessages}
                                    />
                                    <i className="pp-landing pp-comments" />
                                </span>
                            </Link>
                        )}

                    <Link style={{ width: '39px' }} title={i18n.__('Filters')} to={PP_URLS.FILTERS} className="d-none d-lg-block" id="header-filters" aria-label={i18n.__('Filters')}>
                        <span className="button-filters" data-test="toolbar-filters">
                            <Badge
                                badgeFilters
                                value={unseenFilters}
                            />
                            <i className="pp-landing pp-filter" />
                        </span>
                    </Link>

                    <Link style={{ width: '51px' }} title={i18n.__('My profile')} to={PP_URLS.PROFILE} className="header-avatar d-none d-lg-block" id="header-profile" aria-label={i18n.__('My profile')}>
                        <div className="profile-image-cropper mx-2" data-test="toolbar-profile">
                            {checkNested(userData, ['avatar']) ? (!avatarIsFetching && userData.avatar)
                                ? (
                                    <span
                                        className="my-avatar"
                                        style={{ backgroundImage: `url(${buildImgUrl(userData.avatar, 'avatarSmall')})` }}
                                        data-test="toolbar-profile-picture"
                                    />
                                )
                                : AmountLoader
                                : <i className="default-avatar pp-ico pp-avatar" id="default-avatar" />}
                        </div>
                    </Link>
                    <button
                        onClick={() => setIsToggled(UI.RIGHT_MENU)}
                        tabIndex={0}
                        className="btn-only-icon btn-burger"
                        aria-label={i18n.__('Menu')}
                    >
                        <i
                            id="menu-toggler"
                            data-test="side-toolbar-menu-open"
                            className="pp-landing pp-bars menu-toggler"
                        />
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="header-right">
            <div className="d-flex justify-content-end">
                <GoToCompare />
                <div className="d-none d-lg-block" style={{ width: '39px' }} />
                {!isCreate && !size(mSelect) && bookmarkCount > 0 && (
                    <Link
                        title={i18n.__('Bookmarks')}
                        to={PP_URLS.BOOKMARKS}
                        className="d-none d-lg-block"
                        id="header-bookmarks"
                    >
                        <span className="button-bookmarks">
                            <Badge
                                badgeBookmarks
                                value={bookmarkCount}
                            />
                            <i className="pp-landing pp-heart" />
                        </span>
                    </Link>
                )}
                <div className="d-none d-lg-block" style={{ width: '39px' }} />
                <div className="d-none d-lg-block" style={{ width: '39px' }} />
                <div className="d-none d-lg-block" style={{ width: '39px' }} />
                <div className="d-none d-lg-block" style={{ width: '51px' }} />
                <button
                    className="btn-only-icon btn-burger"
                    onClick={() => setIsToggled(UI.RIGHT_MENU)}
                    aria-label={i18n.__('Menu')}
                >
                    <i
                        id="menu-toggler"
                        data-test="toolbar-menu-toggle"
                        className="pp-landing pp-bars menu-toggler"
                    />
                </button>
            </div>
        </div>
    );
};


export default HeaderRight;
