import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { GET_RECENT_SEARCHES } from 'ACTIONS';

export const getRecentSearches = () => apiAction(
    {
        url: API_URLS.RECENT_SEARCHES,
        label: GET_RECENT_SEARCHES,
        method: 'GET',
        auth: false,
        onSuccess: data => dispatch => {
            dispatch({
                type: GET_RECENT_SEARCHES,
                payload: data.content.data
            });
        }
    }
);
