import React from 'react';
import classNames from 'classnames';
import { lazyRetry, loadComponent } from 'MODULES/lazyRetry';

const Modal = loadComponent(() => lazyRetry(() => import('reactstrap/esm/Modal')));


const ModalGdpr = React.lazy(() => import('COMPONENTS/modals/modal_gdpr'));

const className = classNames('', {
    'modal-content': true,
    'modal-gdpr': true

});

const FooterGdpr = ({ i18n, isOpenGdpr, closeGdprModal }) => (
    <Modal
        id="Modal"
        fade={false}
        aria-label="modal"
        className={className}
        labelledBy="Modal"
        modalClassName="modal-subscribe-backdrop"
        scrollable
        backdrop={false}
        onClosed={closeGdprModal}
        isOpen={isOpenGdpr}
        zIndex={1350}
        toggle={closeGdprModal}
        tabIndex={-1}
    >
        <ModalGdpr onUnmount={closeGdprModal} i18n={i18n} />
    </Modal>
);


export default FooterGdpr;
