import React, { useEffect, useState } from 'react';

const SuggestionAnimations = ({ suggestions }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex
                + 1) % suggestions.length);
        }, 4000);

        return () => clearInterval(intervalId);
    }, [suggestions]);

    return (
        <div style={{ marginLeft: '0.2rem' }} id="searchAnimation" className="search__suggestion">
            <p>{suggestions[currentIndex]}</p>
        </div>
    );
};

export default SuggestionAnimations;
