import isObject from 'lodash/isObject';


const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};



export const notify = (message, status) => dispatch => {
    let payload = {
        id: makeid(5),
        position: 'top-right',
        dismissible: true,
        dismissAfter: 5000,
        buttons: []

    };
    if (isObject(message)) {
        payload = { ...payload, ...message };
    } else {
        payload = { ...payload,
            message,
            status };
    }
    dispatch({
        type: 'reapop/upsertNotification',
        payload
    });
};

