import React from 'react';
import Badge from 'COMMON/badge';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ppCompareListCountSelector } from 'MODULES/reselect/selectors/pp_list';
import { getSelectedLanguage } from 'MODULES/reselect/selectors/general';
import { PP_URLS } from 'CONFIG/PP_URLS';

const GoToCompare = () => {
    const compareCount = useSelector(ppCompareListCountSelector);
    const lang = useSelector(getSelectedLanguage);
    return (
        <Link to={PP_URLS.COMPARE_LOTS(lang)} style={{ width: '39px', display: compareCount > 0 ? 'flex' : 'none' }} className="d-none-350">
            <span className="button-compare" data-test="toolbar-compare">
                <Badge
                    badgeCompare
                    value={compareCount}
                />
                <i className="pp-ico pp-bar-chart" />
            </span>
        </Link>
    );
};

export default GoToCompare;
