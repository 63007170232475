import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import { logger } from 'redux-logger/src';

import reducers from './reducers';
import apiMiddleware from './midlewares/redux-thunk-axios';

const process = { env: __DOTENV__ || window.env };

export const history = createBrowserHistory();

const middlewares = [apiMiddleware];

if (process.env.NODE_ENV === 'development' && process.env.REDUX_LOGGER === 'enabled') {
    middlewares.push(logger);
}

const staticReducers = reducers;
function createReducer(asyncReducers) {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers
    });
}




function configureStore(initialState) {
    const store = createStore(
        createReducer(),
        initialState,
        compose(
            applyMiddleware(
                ...middlewares
            ),
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : args => args
        )
    );

    store.asyncReducers = {};

    store.injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(store.asyncReducers));
    };
    return store;
}

export default configureStore();

