import { createSelector } from 'reselect';

export const searchResultsSelector = createSelector(
    state => state.search.searchResults,
    results => results
);

export const searchQuerySelector = createSelector(
    state => state.search.searchQuery,
    results => results
);

export const searchRecentSuggestions = createSelector(
    state => state.search.searchSuggestions,
    results => results
);
